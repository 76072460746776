<!--
 * @Description: 
 * @Author: JunLiangWang
 * @Date: 2023-04-19 10:56:30
 * @LastEditors: JunLiangWang
 * @LastEditTime: 2023-12-11 11:02:22
-->
<template>
  <div  class="markdown-body">
    <component v-bind:is="'myPlan'+$i18n.locale"></component>
  </div>
</template>

<script>
import myPlanzh from '@/config/myPlan/myPlan.md'
import myPlanen from '@/config/myPlan/myPlan(en).md'
export default {
   name:"myPlan",
   components:{
    myPlanzh,
    myPlanen
   }
}
</script>

<style scoped>
.markdown-body{
  padding: 30px;
}
</style>